import React, { useState } from 'react';
import {
  Tooltip,
  Popover,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Swap from '@project-serum/swap-ui';
import { Provider } from '@project-serum/anchor';
import { TokenListContainer } from '@solana/spl-token-registry';
import { useTokenInfos } from '../utils/tokens/names';
import { useSendTransaction } from '../utils/notifications';
import { useWallet } from '../utils/wallet';
import { useConnection } from '../utils/connection';
import { useIsExtensionWidth } from '../utils/utils';
import DialogForm from './DialogForm';

export default function SwapButton({ size }) {
  const isExtensionWidth = useIsExtensionWidth();
  if (isExtensionWidth) {
    return <SwapButtonDialog size={size} />;
  } else {
    return <SwapButtonPopover size={size} />;
  }
}

function SwapButtonDialog({ size }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendTransaction] = useSendTransaction();
  const connection = useConnection();
  const wallet = useWallet();
  const tokenInfos = useTokenInfos();
  const tokenList = tokenInfos && new TokenListContainer(tokenInfos);
  const provider = new NotifyingProvider(connection, wallet, sendTransaction);
  return (
    <>
      <Tooltip title="Buy Cocucoin">
        <a href="https://jup.ag/swap/SOL-COCU" style={{
          color: '#fff',
          textDecoration: 'none',
          fontSize: '1.2rem',
          fontWeight: 'bold',
        }}>
          But Cocucoin
        </a>
      </Tooltip>
      <DialogForm
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Swap
            provider={provider}
            tokenList={tokenList}
            containerStyle={{
              width: '100%',
              boxShadow: 'none',
            }}
          />
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </div>
      </DialogForm>
    </>
  );
}

function SwapButtonPopover({ size }) {
  const [sendTransaction] = useSendTransaction();
  const connection = useConnection();
  const wallet = useWallet();
  const tokenInfos = useTokenInfos();
  const tokenList = tokenInfos && new TokenListContainer(tokenInfos);
  const provider = new NotifyingProvider(connection, wallet, sendTransaction);
  return (
    tokenList && (
      <PopupState variant="popover">
        {(popupState) => (
          <div style={{ display: 'flex' }}>
            {/* <Tooltip title="Swap Tokens">
              <IconButton {...bindTrigger(popupState)} size={size}>
                <SwapHoriz />
              </IconButton>
            </Tooltip> */}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{ style: { borderRadius: '10px' } }}
              disableRestoreFocus
              keepMounted
            >
              <Swap
                provider={provider}
                tokenList={tokenList}
                containerStyle={{ width: '432px' }}
              />
            </Popover>
          </div>
        )}
      </PopupState>
    )
  );
}

class NotifyingProvider extends Provider {
  constructor(
    connection,
    wallet,
    sendTransaction,
  ) {
    super(connection, wallet, {
      commitment: 'recent',
    });
    this.sendTransaction = sendTransaction;
  }

  async send(
    tx,
    signers,
    opts,
  ) {
    return new Promise((onSuccess, onError) => {
      this.sendTransaction(super.send(tx, signers, opts), {
        onSuccess,
        onError,
      });
    });
  }

  async sendAll(
    txs,
    opts,
  ) {
    return new Promise(async (resolve, onError) => {
      let txSigs = [];
      for (const tx of txs) {
        txSigs.push(
          await new Promise((onSuccess) => {
            this.sendTransaction(super.send(tx.tx, tx.signers, opts), {
              onSuccess,
              onError,
            });
          }),
        );
      }
      resolve(txSigs);
    });
  }
}
